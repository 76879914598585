.messageList {
  height: 375px;
  max-width: 254px;
  border-left: 1px solid grey;
}

.mobileMessageList {
  /*height: calc(100vh - 600px);*/
  height: calc(var(--screenHeight) - 90px);
  max-height: calc(var(--screenHeight) - 136px);
  max-width: 100vw;
  border-left: 1px solid grey;
}
