.playerList {
  display: flex;
  width: 254px;
  flex-wrap: wrap;
  height: 100px;
  overflow: auto;
  justify-content: center;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.inviteList {
  display: flex;
  width: 580px;
  flex-wrap: wrap;
  height: 100px;
  overflow: auto;
  justify-content: center;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.avatar {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItem {
  display: flex;
  justify-content: space-around;
  width: 100vw;
}
