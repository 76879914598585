.test {
  color: red;
}

.main {
  display: flex;
  justify-content: center;
  align-self: left;
  width: 854px;
  height: 525px;
}

.wrapper {
  position: relative;
  width: 700px;
}

.options {
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
}

.playerListAndChat {
  height: 525px;
}

.game {
  height: 560px;
}

.mobile {
  display: flex;
  flex-direction: column;
  /*min-height: 100vh;/*100vh == height:100%, the element is stretched to the same height as the screen*/
  min-width: 100vw;
}

.mobileContent {
  flex: 1;
}

.mobileFooter {
  width: 100%;
  height: 50px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  display: flex;
}

.mobileNav {
  flex: 1;
  /* equals*/
  border: 1px solid #3498ff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498ffaa;
  color: white;
}

.login {
  display: flex;
  justify-content: center;
}

.login .container {
  max-width: 400px;
  flex: 1;
}

.login .fb {
  background-color: #3b5998;
  color: white;
  display: block;
  text-align: center;
}

.login .google {
  background-color: #dd4b39;
  color: white;
  display: block;
  text-align: center;
}

.login .logintext {
  display: inline-block;
  min-width: 250px;
}

.login .logins {
  margin: 5px 0;
}
