.bla {
}
.all {
  display: flex;
  flex-direction: column;
  background: #ffffffaa;
}
.roomList {
  height: 500px;
  overflow: auto;
}
.belowRoomList {
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.slimText {
  font-size: "0.666em";
  color: "#97969B";
  font-weight: "lighter";
  padding-bottom: 5;
}

.titleStyle {
  padding-bottom: 5;
  white-space: "nowrap";
  font-weight: 500px;
}

.dataStyle {
  font-size: "1.2em";
  font-weight: 500px;
}
